:root {
}

html, body {
  height: 100vh;
  width: 100vw;
  background-color: #181818;
  /* touch-action: none;
  -webkit-overflow-scrolling: none;
  overflow: hidden;
  overscroll-behavior: none; */
  /* background-color: #000; */
}

.fh {
  height: 100vh;
}

.App {
  width: 100%;
  max-width: 750px;
  margin: 0 auto;
  text-align: center;
}

.login-button {
  -webkit-appearance: button;
  -moz-appearance: button;
  appearance: button;
  text-decoration: none;
  color: initial;
  color: #fff;
  background-color: #1DB954;
  font-size: 14px;
  line-height: 1;
  border-radius: 500px;
  padding: 18px 48px 16px;
  transition-property: background-color;
  transition-duration: .3s;
  border-width: 0;
  letter-spacing: 2px;
  min-width: 160px;
  text-transform: uppercase;
  white-space: normal;
  cursor: pointer;
}

.login-button {
  background-color: #189745;
}

.center-screen {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.row {
  width:100%;
  display: flex;
  justify-content: center;
}

#greeting {  
  color: white;
  font-size: 1.5rem;
  margin: 2rem 0 2rem 0;
}

.white {
  color: white;
}
